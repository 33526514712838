import React, { useState, useEffect } from 'react';
import { useParams, Navigate, Link } from "react-router-dom";
import AuthService from './AuthService';

export default function GameDetails(props) {
  const [game, setGame] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const bearerToken = new AuthService().getToken();
    if(bearerToken != null) {
      fetch(`/games/${id}`, {
        method: 'GET',
        headers: {
          Authorization: bearerToken
        }
      })
      .then(resp => resp.json())
      .then(data => setGame(data))
    }
  }, [id])

  if((new AuthService()).loggedIn()) {
    return <>
      <div>
        <Link to="/games">Back</Link>
        <br/>
        <label> Word </label>
        <p> {game.word} </p>
        </div>
      </>
  } else {
    return <Navigate to="/login" />
  }
}
