import React from 'react'
import ReactDOM from 'react-dom'
import { Routes, Route, BrowserRouter } from "react-router-dom";
import AuthService from './AuthService';
import PlayersList from "./PlayersList";
import PlayerDetails from "./PlayerDetails";
import GamesList from "./GamesList";
import GameDetails from "./GameDetails";
import Login from "./Login";
import Logout from "./Logout";

const App = props => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<GamesList />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/players" element={<PlayersList />} />
      <Route path="/players/:id" exact element={<PlayerDetails />} />
      <Route path="/games" element={<GamesList />} />
      <Route path="/games/:id" exact element={<GameDetails />} />
    </Routes>
  </BrowserRouter>
)

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <App />,
    document.body.appendChild(document.createElement('div')),
  )
})
